import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { LayoutSingleColumn } from '../../components';
import FooterSection from '../LandingPage/FooterSection/Footer';
import TopbarContainer from '../TopbarContainer/TopbarContainer';
import css from './TermsOfServicePage.module.css';

const TermsOfServicesPage = () => {
  const location = useLocation();

  // Scroll to section if there's a hash in the URL
  useEffect(() => {
    const marketplaceAgreementId = location.hash;
    if (marketplaceAgreementId) {
      const section = document.querySelector(marketplaceAgreementId);
      if (section) {
        section.scrollIntoView({ behavior: 'smooth' });
      }
    }
    if (location.pathname.includes('#marketplaceAgreementId')) {
      const section = document.querySelector('#marketplaceAgreementId');
      if (section) {
        section.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [location]);

  const marketplaceAgreementHandler = () => {
    const section = document.querySelector('#marketplaceAgreementId');
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
    }
  }
  const shopperTermsHandler = () => {
    const section = document.querySelector('#shopperTermsId');
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
    }
  }
  return (
    <LayoutSingleColumn
      topbar={<TopbarContainer currentPage="TermsAndConditionsPage" />}
      footer={<FooterSection />}
    >
      <div className={css.container}>
        <h1>Terms and Conditions</h1>
        <p onClick={shopperTermsHandler} style={{ marginBottom: '10px', cursor: 'pointer', fontStyle: 'italic' }} className={css.termsLink}>SHOPPER TERMS & CONDITIONS</p>
        <p onClick={marketplaceAgreementHandler} style={{ marginTop: '0px', cursor: 'pointer', fontStyle: 'italic' }} className={css.termsLink}>STORE OWNER MARKETPLACE AGREEMENT</p>
        <div id="shopperTermsId" className={css.border}>
          <p>SHOPPER TERMS & CONDITIONS</p>
          <p>Last updated November 13, 2024</p>
          <p>AGREEMENT TO OUR LEGAL TERMS</p>
          <p>
            We are Locago LLC ("Company," "we," "us," "our"), a company registered
            in Illinois, United States at 1890 Maple Avenue, Apt 413E, Evanston, IL 60201.
          </p>

          <p>
            We operate the website http://www.locago.shop (the "Site"), as well as any other related
            products and services that refer or link to these legal terms (the "Legal Terms")
            (collectively, the "Services").
          </p>
          <p>
            You can contact us by phone at 5164250455, email at <a href="mailto:info@locago.shop">info@locago.shop</a>, or by mail to 1890
            Maple Avenue, Apt 413E, Evanston, IL 60201, United States.
          </p>
          <p>
            These Legal Terms constitute a legally binding agreement made between you, whether
            personally or on behalf of an entity ("you"), and Locago LLC, concerning your access to
            and use of the Services. You agree that by accessing the Services, you have read,
            understood, and agreed to be bound by all of these Legal Terms. IF YOU DO NOT AGREE WITH
            ALL OF THESE LEGAL TERMS, THEN YOU ARE EXPRESSLY PROHIBITED FROM USING THE SERVICES AND
            YOU MUST DISCONTINUE USE IMMEDIATELY.
          </p>
          <p>
            Supplemental terms and conditions or documents that may be posted on the Services from
            time to time are hereby expressly incorporated herein by reference. We reserve the
            right, in our sole discretion, to make changes or modifications to these Legal Terms at
            any time and for any reason. We will alert you about any changes by updating the "Last
            updated" date of these Legal Terms, and you waive any right to receive specific notice
            of each such change. It is your responsibility to periodically review these Legal Terms
            to stay informed of updates. You will be subject to, and will be deemed to have been
            made aware of and to have accepted, the changes in any revised Legal Terms by your
            continued use of the Services after the date such revised Legal Terms are posted.
          </p>
          <p>
            The Services are intended for users who are at least 18 years old. Persons under the age
            of 18 are not permitted to use or register for the Services.
          </p>
          <p>We recommend that you print a copy of these Legal Terms for your records.</p>
          <div>
            <p>TABLE OF CONTENTS</p>
            <p>
              1. OUR SERVICES
              <br />
              2. INTELLECTUAL PROPERTY RIGHTS
              <br />
              3. USER REPRESENTATIONS
              <br />
              4. USER REGISTRATION
              <br />
              5. PRODUCTS
              <br />
              6. PURCHASES AND PAYMENT
              <br />
              7. RETURN POLICY
              <br />
              8. PROHIBITED ACTIVITIES
              <br />
              9. USER GENERATED CONTRIBUTIONS
              <br />
              10. CONTRIBUTION LICENSE
              <br />
              11. GUIDELINES FOR REVIEWS
              <br />
              12. THIRD-PARTY WEBSITES AND CONTENT
              <br />
              13. SERVICES MANAGEMENT
              <br />
              14. PRIVACY POLICY
              <br />
              15. TERM AND TERMINATION
              <br />
              16. MODIFICATIONS AND INTERRUPTIONS
              <br />
              17. GOVERNING LAW
              <br />
              18. DISPUTE RESOLUTION
              <br />
              19. CORRECTIONS
              <br />
              20. DISCLAIMER
              <br />
              21. LIMITATIONS OF LIABILITY
              <br />
              22. INDEMNIFICATION
              <br />
              23. USER DATA
              <br />
              24. ELECTRONIC COMMUNICATIONS, TRANSACTIONS, AND SIGNATURES
              <br />
              25. SMS TEXT MESSAGING
              <br />
              26. CALIFORNIA USERS AND RESIDENTS
              <br />
              27. MISCELLANEOUS
              <br />
              28. CONTACT US
              <br />
            </p>
          </div>
          <div>
            <p>1. OUR SERVICES</p>
            <p>
              The information provided when using the Services is not intended for distribution to
              or use by any person or entity in any jurisdiction or country where such distribution
              or use would be contrary to law or regulation or which would subject us to any
              registration requirement within such jurisdiction or country. Accordingly, those
              persons who choose to access the Services from other locations do so on their own
              initiative and are solely responsible for compliance with local laws, if and to the
              extent local laws are applicable.
            </p>
            <p>
              The Services are not tailored to comply with industry-specific regulations (Health
              Insurance Portability and Accountability Act (HIPAA), Federal Information Security
              Management Act (FISMA), etc.), so if your interactions would be subjected to such
              laws, you may not use the Services. You may not use the Services in a way that would
              violate the Gramm-Leach-Bliley Act (GLBA).
            </p>
          </div>
          <div>
            <p>2. INTELLECTUAL PROPERTY RIGHTS</p>
            <p>Our intellectual property</p>
            <p>
              We are the owner or the licensee of all intellectual property rights in our Services,
              including all source code, databases, functionality, software, website designs, audio,
              video, text, photographs, and graphics in the Services (collectively, the "Content"),
              as well as the trademarks, service marks, and logos contained therein (the "Marks").
            </p>
            <p>
              Our Content and Marks are protected by copyright and trademark laws (and various other
              intellectual property rights and unfair competition laws) and treaties in the United
              States and around the world.
            </p>
            <p>
              The Content and Marks are provided in or through the Services "AS IS" for
              your personal, non-commercial use only.
            </p>
            <p>Your use of our Services</p>
            <p>
              Subject to your compliance with these Legal Terms, including the "PROHIBITED
              ACTIVITIES" section below, we grant you a non-exclusive, non-transferable,
              revocable license to: access the Services; and download or print a copy of any portion
              of the Content to which you have properly gained access. solely for your personal,
              non-commercial use.
            </p>
            <p>
              Except as set out in this section or elsewhere in our Legal Terms, no part of the
              Services and no Content or Marks may be copied, reproduced, aggregated, republished,
              uploaded, posted, publicly displayed, encoded, translated, transmitted, distributed,
              sold, licensed, or otherwise exploited for any commercial purpose whatsoever, without
              our express prior written permission.
            </p>
            <p>
              If you wish to make any use of the Services, Content, or Marks other than as set out
              in this section or elsewhere in our Legal Terms, please address your request
              to: <a href="mailto:info@locago.shop">info@locago.shop</a>. If we ever grant you the permission to post, reproduce, or
              publicly display any part of our Services or Content, you must identify us as the
              owners or licensors of the Services, Content, or Marks and ensure that any copyright
              or proprietary notice appears or is visible on posting, reproducing, or displaying our
              Content.
            </p>
            <p>
              We reserve all rights not expressly granted to you in and to the Services, Content,
              and Marks.
            </p>
            <p>
              Any breach of these Intellectual Property Rights will constitute a material breach of
              our Legal Terms and your right to use our Services will terminate immediately.
            </p>
            <p>Your submissions</p>
            <p>
              Please review this section and the "PROHIBITED ACTIVITIES" section carefully prior to
              using our Services to understand the (a) rights you give us and (b) obligations you
              have when you post or upload any content through the Services.
            </p>
            <p>
              Submissions: By directly sending us any question, comment, suggestion, idea, feedback,
              or other information about the Services ("Submissions"), you agree to assign to us all
              intellectual property rights in such Submission. You agree that we shall own this
              Submission and be entitled to its unrestricted use and dissemination for any lawful
              purpose, commercial or otherwise, without acknowledgment or compensation to you.
            </p>
            <p>
              You are responsible for what you post or upload: By sending us Submissions through any
              part of the Services you: confirm that you have read and agree with our "PROHIBITED
              ACTIVITIES" and will not post, send, publish, upload, or transmit through the Services
              any Submission that is illegal, harassing, hateful, harmful, defamatory, obscene,
              bullying, abusive, discriminatory, threatening to any person or group, sexually
              explicit, false, inaccurate, deceitful, or misleading; to the extent permissible by
              applicable law, waive any and all moral rights to any such Submission; warrant that
              any such Submission are original to you or that you have the necessary rights
              and licenses to submit such Submissions and that you have full authority to grant us
              the above-mentioned rights in relation to your Submissions; and warrant and represent
              that your Submissions do not constitute confidential information. You are solely
              responsible for your Submissions and you expressly agree to reimburse us for any and
              all losses that we may suffer because of your breach of (a) this section, (b) any
              third party’s intellectual property rights, or (c) applicable law.
            </p>
          </div>
          <div>
            <p>3. USER REPRESENTATIONS</p>
            <p>
              By using the Services, you represent and warrant that: (1) all registration
              information you submit will be true, accurate, current, and complete; (2) you will
              maintain the accuracy of such information and promptly update such registration
              information as necessary; (3) you have the legal capacity and you agree to comply with
              these Legal Terms; (4) you are not a minor in the jurisdiction in which you reside;
              (5) you will not access the Services through automated or non-human means, whether
              through a bot, script or otherwise; (6) you will not use the Services for any illegal
              or unauthorized purpose; and (7) your use of the Services will not violate any
              applicable law or regulation.
            </p>
            <p>
              If you provide any information that is untrue, inaccurate, not current, or incomplete,
              we have the right to suspend or terminate your account and refuse any and all current
              or future use of the Services (or any portion thereof).
            </p>
          </div>
          <div>
            <p>4. USER REGISTRATION</p>
            <p>
              You may be required to register to use the Services. You agree to keep your password
              confidential and will be responsible for all use of your account and password. We
              reserve the right to remove, reclaim, or change a username you select if we determine,
              in our sole discretion, that such username is inappropriate, obscene, or otherwise
              objectionable.
            </p>
          </div>
          <div>
            <p>5. PRODUCTS</p>
            <p>
              We make every effort to display as accurately as possible the colors, features,
              specifications, and details of the products available on the Services. However, we do
              not guarantee that the colors, features, specifications, and details of the products
              will be accurate, complete, reliable, current, or free of other errors, and your
              electronic display may not accurately reflect the actual colors and details of the
              products. All products are subject to availability, and we cannot guarantee that items
              will be in stock. We reserve the right to discontinue any products at any time for any
              reason. Prices for all products are subject to change.
            </p>
          </div>
          <div>
            <p>6. PURCHASES AND PAYMENT</p>
            <p>We accept the following forms of payment:</p>
            <p>-  Visa -  Mastercard -  American Express -  Discover</p>
            <p>
              You agree to provide current, complete, and accurate purchase and account information
              for all purchases made via the Services. You further agree to promptly update account
              and payment information, including email address, payment method, and payment card
              expiration date, so that we can complete your transactions and contact you as needed.
              Sales tax will be added to the price of purchases as deemed required by us. We may
              change prices at any time. All payments shall be in US dollars.
            </p>
            <p>
              You agree to pay all charges at the prices then in effect for your purchases and any
              applicable shipping fees, and you authorize us to charge your chosen payment provider
              for any such amounts upon placing your order. We reserve the right to correct any
              errors or mistakes in pricing, even if we have already requested or received payment.
            </p>
            <p>
              We reserve the right to refuse any order placed through the Services. We may, in our
              sole discretion, limit or cancel quantities purchased per person, per household, or
              per order. These restrictions may include orders placed by or under the same customer
              account, the same payment method, and/or orders that use the same billing or shipping
              address. We reserve the right to limit or prohibit orders that, in our sole judgment,
              appear to be placed by dealers, resellers, or distributors.
            </p>
          </div>
          <div>
            <p>7. RETURN POLICY</p>
            <p>
              Please review our Return Policy posted on the Services prior to making any purchases.
            </p>
          </div>
          <div>
            <p>8. PROHIBITED ACTIVITIES</p>
            <p>
              You may not access or use the Services for any purpose other than that for which we
              make the Services available. The Services may not be used in connection with any
              commercial endeavors except those that are specifically endorsed or approved by us.
            </p>
            <p>
              As a user of the Services, you agree not to: Systematically retrieve data or other
              content from the Services to create or compile, directly or indirectly, a collection,
              compilation, database, or directory without written permission from us. Trick,
              defraud, or mislead us and other users, especially in any attempt to learn sensitive
              account information such as user passwords. Circumvent, disable, or otherwise
              interfere with security-related features of the Services, including features that
              prevent or restrict the use or copying of any Content or enforce limitations on the
              use of the Services and/or the Content contained therein. Disparage, tarnish, or
              otherwise harm, in our opinion, us and/or the Services. Use any information obtained
              from the Services in order to harass, abuse, or harm another person. Make improper use
              of our support services or submit false reports of abuse or misconduct. Use the
              Services in a manner inconsistent with any applicable laws or regulations. Engage
              in unauthorized framing of or linking to the Services. Upload or transmit (or attempt
              to upload or to transmit) viruses, Trojan horses, or other material, including
              excessive use of capital letters and spamming (continuous posting of repetitive text),
              that interferes with any party’s uninterrupted use and enjoyment of the Services or
              modifies, impairs, disrupts, alters, or interferes with the use, features, functions,
              operation, or maintenance of the Services. Engage in any automated use of the system,
              such as using scripts to send comments or messages, or using any data mining, robots,
              or similar data gathering and extraction tools. Delete the copyright or other
              proprietary rights notice from any Content. Attempt to impersonate another user or
              person or use the username of another user. Upload or transmit (or attempt to upload
              or to transmit) any material that acts as a passive or active information collection
              or transmission mechanism, including without limitation, clear graphics interchange
              formats ("gifs"), 1×1 pixels, web bugs, cookies, or other similar devices (sometimes
              referred to as "spyware" or "passive collection mechanisms" or "pcms"). Interfere
              with, disrupt, or create an undue burden on the Services or the networks or services
              connected to the Services. Harass, annoy, intimidate, or threaten any of our employees
              or agents engaged in providing any portion of the Services to you. Attempt to bypass
              any measures of the Services designed to prevent or restrict access to the Services,
              or any portion of the Services. Copy or adapt the Services' software, including but
              not limited to Flash, PHP, HTML, JavaScript, or other code. Except as permitted by
              applicable law, decipher, decompile, disassemble, or reverse engineer any of the
              software comprising or in any way making up a part of the Services. Except as may be
              the result of standard search engine or Internet browser usage, use, launch, develop,
              or distribute any automated system, including without limitation, any spider, robot,
              cheat utility, scraper, or offline reader that accesses the Services, or use or launch
              any unauthorized script or other software. Use a buying agent or purchasing agent to
              make purchases on the Services. Make any unauthorized use of the Services, including
              collecting usernames and/or email addresses of users by electronic or other means for
              the purpose of sending unsolicited email, or creating user accounts by automated means
              or under false pretenses. Use the Services as part of any effort to compete with us or
              otherwise use the Services and/or the Content for any revenue-generating endeavor or
              commercial enterprise. Use the Services to advertise or offer to sell goods and
              services. Sell or otherwise transfer your profile.
            </p>
          </div>
          <div>
            <p>9. USER GENERATED CONTRIBUTIONS</p>
            <p>
              The Services does not offer users to submit or post content. We may provide you with
              the opportunity to create, submit, post, display, transmit, perform, publish,
              distribute, or broadcast content and materials to us or on the Services, including but
              not limited to text, writings, video, audio, photographs, graphics, comments,
              suggestions, or personal information or other material
              (collectively, "Contributions"). Contributions may be viewable by other users of the
              Services and through third-party websites. When you create or make available any
              Contributions, you thereby represent and warrant that:
            </p>
            <p>
              The creation, distribution, transmission, public display, or performance, and the
              accessing, downloading, or copying of your Contributions do not and will not infringe
              the proprietary rights, including but not limited to the copyright, patent, trademark,
              trade secret, or moral rights of any third party.
              <br />
              You are the creator and owner of or have the necessary licenses, rights, consents,
              releases, and permissions to use and to authorize us, the Services, and other users of
              the Services to use your Contributions in any manner contemplated by the Services and
              these Legal Terms.
              <br />
              You have the written consent, release, and/or permission of each and every
              identifiable individual person in your Contributions to use the name or likeness of
              each and every such identifiable individual person to enable inclusion and use of your
              Contributions in any manner contemplated by the Services and these Legal Terms.
              <br />
              Your Contributions are not false, inaccurate, or misleading.
              <br />
              Your Contributions are not unsolicited or unauthorized advertising, promotional
              materials, pyramid schemes, chain letters, spam, mass mailings, or other forms of
              solicitation.
              <br />
              Your Contributions are not obscene, lewd, lascivious, filthy, violent,
              harassing, libelous, slanderous, or otherwise objectionable (as determined by us).
              <br />
              Your Contributions do not ridicule, mock, disparage, intimidate, or abuse anyone.
              <br />
              Your Contributions are not used to harass or threaten (in the legal sense of those
              terms) any other person and to promote violence against a specific person or class of
              people.
              <br />
              Your Contributions do not violate any applicable law, regulation, or rule.
              <br />
              Your Contributions do not violate the privacy or publicity rights of any third party.
              <br />
              Your Contributions do not violate any applicable law concerning child pornography, or
              otherwise intended to protect the health or well-being of minors.
              <br />
              Your Contributions do not include any offensive comments that are connected to race,
              national origin, gender, sexual preference, or physical handicap.
              <br />
              Your Contributions do not otherwise violate, or link to material that violates, any
              provision of these Legal Terms, or any applicable law or regulation.
              <br />
              Any use of the Services in violation of the foregoing violates these Legal Terms and
              may result in, among other things, termination or suspension of your rights to use the
              Services.
              <br />
            </p>
          </div>
          <div>
            <p>10. CONTRIBUTION LICENSE</p>
            <p>
              You and Services agree that we may access, store, process, and use any information and
              personal data that you provide and your choices (including settings).
            </p>
            <p>
              By submitting suggestions or other feedback regarding the Services, you agree that we
              can use and share such feedback for any purpose without compensation to you.
            </p>
            <p>
              We do not assert any ownership over your Contributions. You retain full ownership of
              all of your Contributions and any intellectual property rights or other proprietary
              rights associated with your Contributions. We are not liable for any statements or
              representations in your Contributions provided by you in any area on the Services. You
              are solely responsible for your Contributions to the Services and you expressly agree
              to exonerate us from any and all responsibility and to refrain from any legal action
              against us regarding your Contributions.
            </p>
          </div>
          <div>
            <p>11. GUIDELINES FOR REVIEWS</p>
            <p>
              We may provide you areas on the Services to leave reviews or ratings. When posting a
              review, you must comply with the following criteria: (1) you should have firsthand
              experience with the person/entity being reviewed; (2) your reviews should not contain
              offensive profanity, or abusive, racist, offensive, or hateful language; (3) your
              reviews should not contain discriminatory references based on religion, race, gender,
              national origin, age, marital status, sexual orientation, or disability; (4) your
              reviews should not contain references to illegal activity; (5) you should not be
              affiliated with competitors if posting negative reviews; (6) you should not make any
              conclusions as to the legality of conduct; (7) you may not post any false or
              misleading statements; and (8) you may not organize a campaign encouraging others to
              post reviews, whether positive or negative.
            </p>
            <p>
              We may accept, reject, or remove reviews in our sole discretion. We have absolutely no
              obligation to screen reviews or to delete reviews, even if anyone considers reviews
              objectionable or inaccurate. Reviews are not endorsed by us, and do not necessarily
              represent our opinions or the views of any of our affiliates or partners. We do not
              assume liability for any review or for any claims, liabilities, or losses resulting
              from any review. By posting a review, you hereby grant to us a perpetual,
              non-exclusive, worldwide, royalty-free, fully paid, assignable, and sublicensable
              right and license to reproduce, modify, translate, transmit by any means, display,
              perform, and/or distribute all content relating to review.
            </p>
          </div>
          <div>
            <p>12. THIRD-PARTY WEBSITES AND CONTENT</p>
            <p>
              The Services may contain (or you may be sent via the Site) links to other websites
              ("Third-Party Websites") as well as articles, photographs, text, graphics, pictures,
              designs, music, sound, video, information, applications, software, and other content
              or items belonging to or originating from third parties ("Third-Party Content").
              Such Third-Party Websites and Third-Party Content are not investigated, monitored, or
              checked for accuracy, appropriateness, or completeness by us, and we are not
              responsible for any Third-Party Websites accessed through the Services or
              any Third-Party Content posted on, available through, or installed from the Services,
              including the content, accuracy, offensiveness, opinions, reliability, privacy
              practices, or other policies of or contained in the Third-Party Websites or
              the Third-Party Content. Inclusion of, linking to, or permitting the use or
              installation of any Third-Party Websites or any Third-Party Content does not imply
              approval or endorsement thereof by us. If you decide to leave the Services and access
              the Third-Party Websites or to use or install any Third-Party Content, you do so at
              your own risk, and you should be aware these Legal Terms no longer govern. You should
              review the applicable terms and policies, including privacy and data gathering
              practices, of any website to which you navigate from the Services or relating to any
              applications you use or install from the Services. Any purchases you make
              through Third-Party Websites will be through other websites and from other companies,
              and we take no responsibility whatsoever in relation to such purchases which are
              exclusively between you and the applicable third party. You agree and acknowledge that
              we do not endorse the products or services offered on Third-Party Websites and you
              shall hold us blameless from any harm caused by your purchase of such products or
              services. Additionally, you shall hold us blameless from any losses sustained by you
              or harm caused to you relating to or resulting in any way from any Third-Party Content
              or any contact with Third-Party Websites.
            </p>
          </div>
          <div>
            <p>13. SERVICES MANAGEMENT</p>
            <p>
              We reserve the right, but not the obligation, to: (1) monitor the Services for
              violations of these Legal Terms; (2) take appropriate legal action against anyone who,
              in our sole discretion, violates the law or these Legal Terms, including without
              limitation, reporting such user to law enforcement authorities; (3) in our sole
              discretion and without limitation, refuse, restrict access to, limit the availability
              of, or disable (to the extent technologically feasible) any of your Contributions or
              any portion thereof; (4) in our sole discretion and without limitation, notice, or
              liability, to remove from the Services or otherwise disable all files and content that
              are excessive in size or are in any way burdensome to our systems; and (5) otherwise
              manage the Services in a manner designed to protect our rights and property and to
              facilitate the proper functioning of the Services.
            </p>
          </div>
          <div>
            <p>14. PRIVACY POLICY</p>
            <p>
              We care about data privacy and security. By using the Services, you agree to be bound
              by our Privacy Policy posted on the Services, which is incorporated into these Legal
              Terms. Please be advised the Services are hosted in the United States. If you access
              the Services from any other region of the world with laws or other requirements
              governing personal data collection, use, or disclosure that differ from applicable
              laws in the United States, then through your continued use of the Services, you are
              transferring your data to the United States, and you expressly consent to have your
              data transferred to and processed in the United States.
            </p>
          </div>
          <div>
            <p>15. TERM AND TERMINATION</p>
            <p>
              These Legal Terms shall remain in full force and effect while you use the Services.
              WITHOUT LIMITING ANY OTHER PROVISION OF THESE LEGAL TERMS, WE RESERVE THE RIGHT TO, IN
              OUR SOLE DISCRETION AND WITHOUT NOTICE OR LIABILITY, DENY ACCESS TO AND USE OF THE
              SERVICES (INCLUDING BLOCKING CERTAIN IP ADDRESSES), TO ANY PERSON FOR ANY REASON OR
              FOR NO REASON, INCLUDING WITHOUT LIMITATION FOR BREACH OF ANY REPRESENTATION,
              WARRANTY, OR COVENANT CONTAINED IN THESE LEGAL TERMS OR OF ANY APPLICABLE LAW OR
              REGULATION. WE MAY TERMINATE YOUR USE OR PARTICIPATION IN THE SERVICES OR DELETE YOUR
              ACCOUNT AND ANY CONTENT OR INFORMATION THAT YOU POSTED AT ANY TIME, WITHOUT WARNING,
              IN OUR SOLE DISCRETION.
            </p>
            <p>
              If we terminate or suspend your account for any reason, you are prohibited from
              registering and creating a new account under your name, a fake or borrowed name, or
              the name of any third party, even if you may be acting on behalf of the third party.
              In addition to terminating or suspending your account, we reserve the right to take
              appropriate legal action, including without limitation pursuing civil, criminal, and
              injunctive redress.
            </p>
          </div>
          <div>
            <p>16. MODIFICATIONS AND INTERRUPTIONS</p>
            <p>
              We reserve the right to change, modify, or remove the contents of the Services at any
              time or for any reason at our sole discretion without notice. However, we have no
              obligation to update any information on our Services. We also reserve the right to
              modify or discontinue all or part of the Services without notice at any time. We will
              not be liable to you or any third party for any modification, price change,
              suspension, or discontinuance of the Services.
            </p>
            <p>
              We cannot guarantee the Services will be available at all times. We may experience
              hardware, software, or other problems or need to perform maintenance related to the
              Services, resulting in interruptions, delays, or errors. We reserve the right to
              change, revise, update, suspend, discontinue, or otherwise modify the Services at any
              time or for any reason without notice to you. You agree that we have no liability
              whatsoever for any loss, damage, or inconvenience caused by your inability to access
              or use the Services during any downtime or discontinuance of the Services. Nothing in
              these Legal Terms will be construed to obligate us to maintain and support the
              Services or to supply any corrections, updates, or releases in connection therewith.
            </p>
          </div>
          <div>
            <p>17. GOVERNING LAW</p>
            <p>
              These Legal Terms and your use of the Services are governed by and construed in
              accordance with the laws of the State of Illinois applicable to agreements made and to
              be entirely performed within the State of Illinois, without regard to its conflict of
              law principles.
            </p>
          </div>
          <div>
            <p>18. DISPUTE RESOLUTION</p>
            <p>Informal Negotiations</p>
            <p>
              To expedite resolution and control the cost of any dispute, controversy, or claim
              related to these Legal Terms (each a "Dispute" and collectively, the "Disputes")
              brought by either you or us (individually, a "Party" and collectively, the "Parties"),
              the Parties agree to first attempt to negotiate any Dispute (except those Disputes
              expressly provided below) informally for at least thirty (30) days before initiating
              arbitration. Such informal negotiations commence upon written notice from one Party to
              the other Party.
            </p>
            <p>Binding Arbitration</p>
            <p>
              If the Parties are unable to resolve a Dispute through informal negotiations, the
              Dispute (except those Disputes expressly excluded below) will be finally and
              exclusively resolved by binding arbitration. YOU UNDERSTAND THAT WITHOUT THIS
              PROVISION, YOU WOULD HAVE THE RIGHT TO SUE IN COURT AND HAVE A JURY TRIAL. The
              arbitration shall be commenced and conducted under the Commercial Arbitration Rules of
              the American Arbitration Association ("AAA") and, where appropriate, the AAA’s
              Supplementary Procedures for Consumer Related Disputes ("AAA Consumer Rules"), both of
              which are available at the American Arbitration Association (AAA) website. Your
              arbitration fees and your share of arbitrator compensation shall be governed by the
              AAA Consumer Rules and, where appropriate, limited by the AAA Consumer Rules. The
              arbitration may be conducted in person, through the submission of documents, by phone,
              or online. The arbitrator will make a decision in writing, but need not provide a
              statement of reasons unless requested by either Party. The arbitrator must follow
              applicable law, and any award may be challenged if the arbitrator fails to do so.
              Except where otherwise required by the applicable AAA rules or applicable law, the
              arbitration will take place in Cook County, Illinois. Except as otherwise provided
              herein, the Parties may litigate in court to compel arbitration, stay proceedings
              pending arbitration, or to confirm, modify, vacate, or enter judgment on the award
              entered by the arbitrator.
            </p>
            <p>
              If for any reason, a Dispute proceeds in court rather than arbitration, the Dispute
              shall be commenced or prosecuted in the  state and federal courts located in Delaware,
              and the Parties hereby consent to, and waive all defenses of lack of personal
              jurisdiction, and forum non conveniens with respect to venue and jurisdiction in
              such state and federal courts. Application of the United Nations Convention on
              Contracts for the International Sale of Goods and the Uniform Computer Information
              Transaction Act (UCITA) are excluded from these Legal Terms.
            </p>
            <p>
              In no event shall any Dispute brought by either Party related in any way to the
              Services be commenced more than one (1) years after the cause of action arose. If this
              provision is found to be illegal or unenforceable, then neither Party will elect to
              arbitrate any Dispute falling within that portion of this provision found to be
              illegal or unenforceable and such Dispute shall be decided by a court of competent
              jurisdiction within the courts listed for jurisdiction above, and the Parties agree to
              submit to the personal jurisdiction of that court.
            </p>
            <p>Restrictions</p>
            <p>
              The Parties agree that any arbitration shall be limited to the Dispute between the
              Parties individually. To the full extent permitted by law, (a) no arbitration shall be
              joined with any other proceeding; (b) there is no right or authority for any Dispute
              to be arbitrated on a class-action basis or to utilize class action procedures; and
              (c) there is no right or authority for any Dispute to be brought in a purported
              representative capacity on behalf of the general public or any other persons.
            </p>
            <p>Exceptions to Informal Negotiations and Arbitration</p>
            <p>
              The Parties agree that the following Disputes are not subject to the above provisions
              concerning informal negotiations binding arbitration: (a) any Disputes seeking to
              enforce or protect, or concerning the validity of, any of the intellectual property
              rights of a Party; (b) any Dispute related to, or arising from, allegations of theft,
              piracy, invasion of privacy, or unauthorized use; and (c) any claim for injunctive
              relief. If this provision is found to be illegal or unenforceable, then neither Party
              will elect to arbitrate any Dispute falling within that portion of this provision
              found to be illegal or unenforceable and such Dispute shall be decided by a court of
              competent jurisdiction within the courts listed for jurisdiction above, and the
              Parties agree to submit to the personal jurisdiction of that court.
            </p>
          </div>
          <div>
            <p>19. CORRECTIONS</p>
            <p>
              There may be information on the Services that contains typographical errors,
              inaccuracies, or omissions, including descriptions, pricing, availability, and various
              other information. We reserve the right to correct any errors, inaccuracies, or
              omissions and to change or update the information on the Services at any time, without
              prior notice.
            </p>
          </div>
          <div>
            <p>20. DISCLAIMER</p>
            <p>
              THE SERVICES ARE PROVIDED ON AN AS-IS AND AS-AVAILABLE BASIS. YOU AGREE THAT YOUR USE
              OF THE SERVICES WILL BE AT YOUR SOLE RISK. TO THE FULLEST EXTENT PERMITTED BY LAW, WE
              DISCLAIM ALL WARRANTIES, EXPRESS OR IMPLIED, IN CONNECTION WITH THE SERVICES AND YOUR
              USE THEREOF, INCLUDING, WITHOUT LIMITATION, THE IMPLIED WARRANTIES OF MERCHANTABILITY,
              FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT. WE MAKE NO WARRANTIES OR
              REPRESENTATIONS ABOUT THE ACCURACY OR COMPLETENESS OF THE SERVICES' CONTENT OR THE
              CONTENT OF ANY WEBSITES OR MOBILE APPLICATIONS LINKED TO THE SERVICES AND WE WILL
              ASSUME NO LIABILITY OR RESPONSIBILITY FOR ANY (1) ERRORS, MISTAKES, OR INACCURACIES OF
              CONTENT AND MATERIALS, (2) PERSONAL INJURY OR PROPERTY DAMAGE, OF ANY NATURE
              WHATSOEVER, RESULTING FROM YOUR ACCESS TO AND USE OF THE SERVICES, (3)
              ANY UNAUTHORIZED ACCESS TO OR USE OF OUR SECURE SERVERS AND/OR ANY AND ALL PERSONAL
              INFORMATION AND/OR FINANCIAL INFORMATION STORED THEREIN, (4) ANY INTERRUPTION OR
              CESSATION OF TRANSMISSION TO OR FROM THE SERVICES, (5) ANY BUGS, VIRUSES, TROJAN
              HORSES, OR THE LIKE WHICH MAY BE TRANSMITTED TO OR THROUGH THE SERVICES BY ANY THIRD
              PARTY, AND/OR (6) ANY ERRORS OR OMISSIONS IN ANY CONTENT AND MATERIALS OR FOR ANY LOSS
              OR DAMAGE OF ANY KIND INCURRED AS A RESULT OF THE USE OF ANY CONTENT POSTED,
              TRANSMITTED, OR OTHERWISE MADE AVAILABLE VIA THE SERVICES. WE DO NOT WARRANT, ENDORSE,
              GUARANTEE, OR ASSUME RESPONSIBILITY FOR ANY PRODUCT OR SERVICE ADVERTISED OR OFFERED
              BY A THIRD PARTY THROUGH THE SERVICES, ANY HYPERLINKED WEBSITE, OR ANY WEBSITE OR
              MOBILE APPLICATION FEATURED IN ANY BANNER OR OTHER ADVERTISING, AND WE WILL NOT BE A
              PARTY TO OR IN ANY WAY BE RESPONSIBLE FOR MONITORING ANY TRANSACTION BETWEEN YOU AND
              ANY THIRD-PARTY PROVIDERS OF PRODUCTS OR SERVICES. AS WITH THE PURCHASE OF A PRODUCT
              OR SERVICE THROUGH ANY MEDIUM OR IN ANY ENVIRONMENT, YOU SHOULD USE YOUR
              BEST JUDGMENT AND EXERCISE CAUTION WHERE APPROPRIATE.
            </p>
          </div>
          <div>
            <p>21. LIMITATIONS OF LIABILITY</p>
            <p>
              IN NO EVENT WILL WE OR OUR DIRECTORS, EMPLOYEES, OR AGENTS BE LIABLE TO YOU OR ANY
              THIRD PARTY FOR ANY DIRECT, INDIRECT, CONSEQUENTIAL, EXEMPLARY, INCIDENTAL, SPECIAL,
              OR PUNITIVE DAMAGES, INCLUDING LOST PROFIT, LOST REVENUE, LOSS OF DATA, OR OTHER
              DAMAGES ARISING FROM YOUR USE OF THE SERVICES, EVEN IF WE HAVE BEEN ADVISED OF THE
              POSSIBILITY OF SUCH DAMAGES. NOTWITHSTANDING ANYTHING TO THE CONTRARY CONTAINED
              HEREIN, OUR LIABILITY TO YOU FOR ANY CAUSE WHATSOEVER AND REGARDLESS OF THE FORM OF
              THE ACTION, WILL AT ALL TIMES BE LIMITED TO THE AMOUNT PAID, IF ANY, BY YOU TO
              US. CERTAIN US STATE LAWS AND INTERNATIONAL LAWS DO NOT ALLOW LIMITATIONS ON IMPLIED
              WARRANTIES OR THE EXCLUSION OR LIMITATION OF CERTAIN DAMAGES. IF THESE LAWS APPLY TO
              YOU, SOME OR ALL OF THE ABOVE DISCLAIMERS OR LIMITATIONS MAY NOT APPLY TO YOU, AND YOU
              MAY HAVE ADDITIONAL RIGHTS.
            </p>
          </div>
          <div>
            <p>22. INDEMNIFICATION</p>
            <p>
              You agree to defend, indemnify, and hold us harmless, including our subsidiaries,
              affiliates, and all of our respective officers, agents, partners, and employees, from
              and against any loss, damage, liability, claim, or demand, including reasonable
              attorneys’ fees and expenses, made by any third party due to or arising out of: (1)
              use of the Services; (2) breach of these Legal Terms; (3) any breach of your
              representations and warranties set forth in these Legal Terms; (4) your violation of
              the rights of a third party, including but not limited to intellectual property
              rights; or (5) any overt harmful act toward any other user of the Services with whom
              you connected via the Services. Notwithstanding the foregoing, we reserve the right,
              at your expense, to assume the exclusive defense and control of any matter for which
              you are required to indemnify us, and you agree to cooperate, at your expense, with
              our defense of such claims. We will use reasonable efforts to notify you of any such
              claim, action, or proceeding which is subject to this indemnification upon becoming
              aware of it.
            </p>
          </div>
          <div>
            <p>23. USER DATA</p>
            <p>
              We will maintain certain data that you transmit to the Services for the purpose of
              managing the performance of the Services, as well as data relating to your use of the
              Services. Although we perform regular routine backups of data, you are solely
              responsible for all data that you transmit or that relates to any activity you have
              undertaken using the Services. You agree that we shall have no liability to you for
              any loss or corruption of any such data, and you hereby waive any right of action
              against us arising from any such loss or corruption of such data.
            </p>
          </div>
          <div>
            <p>24. ELECTRONIC COMMUNICATIONS, TRANSACTIONS, AND SIGNATURES</p>
            <p>
              Visiting the Services, sending us emails, and completing online forms constitute
              electronic communications. You consent to receive electronic communications, and you
              agree that all agreements, notices, disclosures, and other communications we provide
              to you electronically, via email and on the Services, satisfy any legal requirement
              that such communication be in writing. YOU HEREBY AGREE TO THE USE OF ELECTRONIC
              SIGNATURES, CONTRACTS, ORDERS, AND OTHER RECORDS, AND TO ELECTRONIC DELIVERY OF
              NOTICES, POLICIES, AND RECORDS OF TRANSACTIONS INITIATED OR COMPLETED BY US OR VIA THE
              SERVICES. You hereby waive any rights or requirements under any statutes, regulations,
              rules, ordinances, or other laws in any jurisdiction which require an original
              signature or delivery or retention of non-electronic records, or to payments or the
              granting of credits by any means other than electronic means.
            </p>
          </div>
          <div>
            <p>25. SMS TEXT MESSAGING</p>
            <p>Opting Out</p>
            <p>
              If at any time you wish to stop receiving SMS messages from us, simply reply to the
              text with "STOP.” You may receive an SMS message confirming your opt out.
            </p>
            <p>Message and Data Rates</p>
            <p>
              Please be aware that message and data rates may apply to any SMS messages sent or
              received. The rates are determined by your carrier and the specifics of your mobile
              plan.
            </p>
            <p>Support</p>
            <p>
              If you have any questions or need assistance regarding our SMS communications, please
              email us at <a href="mailto:info@locago.shop">info@locago.shop</a> or call at 5164250455. 
            </p>
          </div>
          <div>
            <p>26. CALIFORNIA USERS AND RESIDENTS</p>
            <p>
              If any complaint with us is not satisfactorily resolved, you can contact the Complaint
              Assistance Unit of the Division of Consumer Services of the California Department of
              Consumer Affairs in writing at 1625 North Market Blvd., Suite N 112, Sacramento,
              California 95834 or by telephone at (800) 952-5210 or (916) 445-1254.
            </p>
          </div>
          <div>
            <p>27. MISCELLANEOUS</p>
            <p>
              These Legal Terms and any policies or operating rules posted by us on the Services or
              in respect to the Services constitute the entire agreement and understanding between
              you and us. Our failure to exercise or enforce any right or provision of these Legal
              Terms shall not operate as a waiver of such right or provision. These Legal Terms
              operate to the fullest extent permissible by law. We may assign any or all of our
              rights and obligations to others at any time. We shall not be responsible or liable
              for any loss, damage, delay, or failure to act caused by any cause beyond our
              reasonable control. If any provision or part of a provision of these Legal Terms is
              determined to be unlawful, void, or unenforceable, that provision or part of the
              provision is deemed severable from these Legal Terms and does not affect the validity
              and enforceability of any remaining provisions. There is no joint venture,
              partnership, employment or agency relationship created between you and us as a result
              of these Legal Terms or use of the Services. You agree that these Legal Terms will not
              be construed against us by virtue of having drafted them. You hereby waive any and
              all defenses you may have based on the electronic form of these Legal Terms and the
              lack of signing by the parties hereto to execute these Legal Terms.
            </p>
          </div>
          <div>
            <p>28. CONTACT US</p>
            <p>
              In order to resolve a complaint regarding the Services or to receive further
              information regarding use of the Services, please contact us at:
            </p>
            <p>
              Locago LLC
              <br />
              1890 Maple Avenue
              <br />
              Apt 413E
              <br />
              Evanston, IL 60201
              <br />
              United States <br />
              Phone: 5164250455 
              <br />
              <a href="mailto:info@locago.shop">info@locago.shop</a>
            </p>
          </div>
        </div>
        <div id="marketplaceAgreementId" className={css.border}>
          <p>STORE OWNER MARKETPLACE AGREEMENT</p>
          <p>Last updated December 6th, 2024</p>
          <p>AGREEMENT TO OUR LEGAL TERMS</p>
          <p>
            LOCAGO LLC, AN ILLINOIS CORPORATION (“LOCAGO”), IS WILLING TO ALLOW YOU TO PARTICIPATE
            IN ITS ONLINE MARKETPLACE (THE “LOCAGO MARKETPLACE”) FOR THE SALE OF RETAIL PRODUCTS,
            SUBJECT TO OUR APPROVAL AS SET FORTH IN SECTION 2 BELOW, IF YOU ACCEPT ALL OF THE TERMS,
            CONDITIONS AND PROVISIONS OF THIS AGREEMENT AND AGREE TO BE BOUND BY THEM. BY CLICKING
            ON “I ACCEPT”, YOU WILL INDICATE YOUR AGREEMENT WITH ALL OF THE TERMS, CONDITIONS AND
            PROVISIONS OF THIS AGREEMENT AND AGREE TO BE BOUND BY THEM. IF YOU ARE ACTING ON BEHALF
            OF A CORPORATION OR OTHER ENTITY, YOUR ACCEPTANCE CONSTITUTES YOUR REPRESENTATION AND
            WARRANTY THAT YOU HAVE THE AUTHORITY TO BIND YOUR CORPORATION OR OTHER ENTITY TO THESE
            TERMS. BY CLICKING ON “I ACCEPT,” YOU ARE ALSO AGREEING TO BE BOUND BY THE TERMS OF OUR
            PRIVACY POLICY WHICH CAN BE FOUND ON THIS SITE’S HOME PAGE.
          </p>
          <p>
            ALL REFERENCES TO “WE”, “US”, “OUR” OR “THIS SITE” SHALL MEAN LOCAGO AND THIS WEBSITE,
            LOCAGO.SHOP. IF YOU ARE ACTING ON BEHALF OF A CORPORATION OR OTHER ENTITY, THEN ALL
            REFERENCES TO “YOU” MEANS THE CORPORATION OR OTHER ENTITY YOU REPRESENT. YOU AND WE ARE
            EACH A “PARTY” AND TOGETHER ARE THE “PARTIES.”
          </p>
          <p>
            1. Definitions. Capitalized terms have the meanings set out in this Section 1, or in the
            section in which they first appear in this Agreement:
          </p>
          <p>“Customer Data” means all Personal Information and Usage Data.</p>
          <p>
            “Laws” means all applicable federal, state, local and foreign laws, statutes, rules,
            regulations and orders.
          </p>
          <p>
            “Locago Marketplace” means the online service at Locago.shop that permits Sellers to
            offer their Products for sale to the public through the Internet or a mobile application
            and processes transactions for and on behalf of Sellers in exchange for fees in
            accordance with this Agreement.
          </p>
          <p>
            “Personal Information” means any information regarding any customers of the Locago
            Marketplace that may be used to identify them.
          </p>
          <p>
            “Products” means any products that are retail goods, may be legally sold to the general
            public in the United States, and may be safely shipped utilizing the United States
            Postal Service and recognized national courier services, including DHL, FedEx, and UPS.
          </p>
          <p>
            “Remittance Amount” means gross revenues from the Sale of a Seller’s Product to an
            end-user customer via the Locago Marketplace, less Selling Fees retained by us in
            accordance with this Agreement.
          </p>
          <p>
            “Return Policy” means the return policy specified by Locago for the return of Products
            purchased via the Locago Marketplace.
          </p>
          <p>
            “Sale” of a Product for purposes of payment of a Remittance Amount to a Seller means:
            (i) the initiation of a purchase transaction via the Locago Marketplace for a Product
            listed on the Locago Marketplace, and (ii) payment in full for the purchased Product and
            receipt by the Locago Marketplace of the gross sales proceeds.
          </p>
          <p>
            “Seller” means an individual person or entity that is registered and authorized to
            participate in the Locago Marketplace for purposes of offering Products for sale to the
            public.
          </p>
          <p>
            “Selling Fees” means the fee calculated as follows: a mutually agreed upon percentage of
            5% of gross sales of a Product listed on the Locago Marketplace, plus any payment
            processing fees required by Stripe or any other transaction processor or processors
            designated by us from time to time.
          </p>
          <p>
            “Subscription Fees” means the monthly fees payable in advance for participation in the
            Locago Marketplace.
          </p>
          <p>“Term” has the meaning set forth in Section 29.  </p>
          <p>
            2.     Agreement; Modification. Our agreement with you consists of this Internet
            Marketplace Agreement (this “Agreement”) plus our Privacy Policy, which is incorporated
            herein. We reserve the right to modify this Agreement and/or our Privacy Policy at any
            time by posting a modified Agreement and/or Privacy Policy that is accessible on this
            page and by giving you prior notice of a modification. You should check this Agreement
            and our Privacy Policy periodically for modifications by scrolling to the bottom of this
            page for a listing of material modifications and their effective dates.
          </p>
          <p>
            IF ANY MODIFICATION IS UNACCEPTABLE TO YOU, YOUR ONLY RECOURSE IS TO TERMINATE THIS
            AGREEMENT. YOUR CONTINUED USE OF THIS SITE FOLLOWING OUR POSTING OF AN MODIFIED
            AGREEMENT OR PRIVACY POLICY OR PROVIDING YOU NOTICE OF A MODIFICATION WILL CONSTITUTE
            YOUR BINDING ACCEPTANCE OF THE MODIFIED AGREEMENT OR PRIVACY POLICY.
          </p>
          <p>3.     Right to Participate in the Locago Marketplace; Registration.</p>
          <p>
            a.     Your participation in the Locago Marketplace is subject to our prior written
            approval, which may be withheld in our sole discretion. This Agreement will not be
            legally binding unless and until we provide written notice of acceptance to you as a
            participant in the Locago Marketplace. You agree to our making inquiries to verify your
            identity and to determine the good standing of you personally and your business. Persons
            under 18 years of age are not allowed to participate in the Locago Marketplace.
            Additional eligibility requirements for participation in the Locago Marketplace are
            determined in our sole discretion.
          </p>
          <p>
            b.     You must register to participate in the Locago Marketplace. You agree (i) to
            provide current, complete and accurate information about you in your profile as prompted
            by our online registration form (“Your Registration Information”), and (ii) to maintain
            and update Your Registration Information to keep it current, complete and accurate. You
            represent and warrant that Your Registration Information is and will continue to be
            current, complete and accurate. You authorize us to verify Your Registration Information
            at any time and from time to time. If any of Your Registration Information is not
            current, complete and accurate, we may suspend or terminate your participation in the
            Locago Marketplace.
          </p>
          <p>
            c.     As part of the registration process, you must also provide us with access to a
            list of your current inventory of available and eligible Products. Access can be
            provided through your Point of Sale (POS) system or other means.
          </p>
          <p>
            4.     License to You. Subject to the terms and conditions hereof and only for the term
            hereof, we hereby grant to you a revocable, worldwide, non-exclusive, non-transferable,
            non-sublicensable, limited right and license to participate as a Seller in the Locago
            Marketplace, and to display, offer for sale and sell your Products to the public via the
            Locago Marketplace.
          </p>
          <p>5.     Use of the Locago Marketplace.</p>
          <p>
            a.     All ad copy, artwork, and branding to be used by you on the Locago Marketplace is
            subject to our prior review and approval, but our approval right does not mean that we
            assume any responsibility for your compliance with all applicable laws and the
            intellectual property and other rights of third parties, all of which are your sole
            responsibility.
          </p>
          <p>
            b.     You are not authorized to use or display pornography, images of children,
            sexually provocative content, racially, sexually or ethnically discriminatory content,
            political information or advocacy, pyramid schemes, franchise or business opportunities
            or any content that in our sole discretion is illegal, immoral, defamatory,
            objectionable or inappropriate.
          </p>
          <p>
            c.     You agree not to use any marketing or advertising that is false, misleading or
            otherwise in violation of any applicable laws or regulations, including without
            limitation, the Federal Trade Commission Act and regulations promulgated thereunder,
            state consumer protection laws and any federal, state, local and foreign laws regarding
            spam and text messages.
          </p>
          <p>
            d.     You are solely responsible for the legality of your content on the Locago
            Marketplace.
          </p>
          <p>
            e.     We have the sole right to determine whether a product is eligible to be offered
            and sold on the Locago Marketplace. If we determine that a product being offered for
            sale on the Locago Marketplace is not an eligible product, it must be immediately
            removed from the Locago Marketplace, and any such determination by us is final, binding
            and not subject to appeal or discussion. Failure to so remove such a product will
            constitute a breach of this Agreement.
          </p>
          <p>
            6.     Subscription Fees. You will pay the Subscription Fee each month on or before the
            tenth day of the month. The initial Subscription Fee will be $0, and is subject to
            increase or decrease from time to time at our option on thirty days’ advance notice.
          </p>
          <p>
            7.     Product Display. We will display your Products and promotional messages on the
            Locago Marketplace in accordance with our policies. You will provide us with an accurate
            description of each Product including item description, item condition and item photos.
          </p>
          <p>
            8.     Payment Processing. All Sales of your Products shall be between you and the
            purchaser. Payment processing for all Sales will be by Stripe or any other transaction
            processor or processors designated by us from time to time.
          </p>
          <p>
            9.     Shipping. To the extent customers can order products for shipping and if
            requested by the customer, unless you have notified us that you have canceled an order,
            you will ship sold Products as directed by the customer within five (5) calendar days of
            the date of the purchase transaction on the Locago Marketplace.
          </p>
          <p>
            10.  Order Pickup. If requested by the customer, unless you have notified us that you
            have canceled an order, you will hold the ordered Products at your store location until
            the customer picks up the ordered Products or five (5) days have passed since the order
            was placed on the Locago Marketplace. If a customer fails to pick up the ordered
            Products within the five (5) day period, the order will be cancelled. We shall refund
            promptly all sales proceeds to the customer, less any payment processing fees required
            by Stripe or any other transaction processor or processors.
          </p>
          <p>
            11.  Remittance Amounts. For each completed Sale of your Products transacted through the
            Locago Marketplace, we will retain the gross sales proceeds until all payments for the
            Product have been processed by the Locago Marketplace and paid in full, and the fourteen
            (14) day Product return period stipulated in our Return Policy has expired, at which
            time we will retain the Selling Fee and pay the Remittance Amount to you in accordance
            with our then-current payment schedule.
          </p>
          <p>
            12.  Returns and Refunds. For Product returns requested by a customer within the
            fourteen (14) day Product return period, upon receipt of written notice from you of
            satisfactory receipt of the returned item, we shall refund promptly all sales proceeds
            to the customer, less any payment processing fees required by Stripe or any other
            transaction processor or processors as stipulated in our Return Policy. You are
            responsible for handling all Product returns and notifying us promptly when you have
            received a returned item from a customer.
          </p>
          <p>
            13.  Taxes. You are solely responsible for all federal, state, local and foreign taxes
            levied on Sales, including without limitation all applicable sales taxes. You shall
            register for sales and use tax collection purposes with all applicable governmental
            authorities. You are solely responsible for ensuring that all taxes and related charges
            relating to Sales of your Products are paid timely and in full. We assume no
            responsibility for any taxes and related charges.
          </p>
          <p>
            14.  Costs and Expenses. You are solely responsible for all of your costs and expenses
            associated with the promotion of Products through the Locago Marketplace, including
            without limitation, costs of advertising, mailing, marketing and website operations.
          </p>
          <p>
            15.  Your Representations and Warranties. You represent and warrant throughout the Term
            that: (i) you have the right, power and authority to enter into this Agreement; (ii)
            this Agreement is a valid and binding obligation enforceable in accordance with its
            terms, subject to the effects of applicable bankruptcy, insolvency and similar laws
            affecting creditors’ rights generally and general principles of equity; (iii) you have
            legal title to and the right to offer for sale and sell all Products listed by you on
            the Locago Marketplace; (iv) all Products listed by you on the Locago Marketplace will
            be genuine, authentic and accurately described; (v) you are registered for sales and use
            tax collection purposes in all states in which your Products will be sold on the Locago
            Marketplace; (vi) your marketing and sale of Products on the Locago Marketplace will
            comply with all applicable Laws; (vii) you own all right, title and interest in Your IP
            and have the right to grant the licenses in Your IP and Third Party IP; (viii) your
            advertising and promotion of Products on the Locago Marketplace will not constitute
            false, deceptive or unfair advertising or disparagement under any Laws or otherwise be
            in violation of any Laws; and (ix) Your IP and Third Party IP do not and will not
            violate any copyright, trademark or other intellectual property right or right of
            privacy or publicity of any third-party or any Laws.
          </p>
          <p>
            16.  Your Indemnification. You will defend, indemnify and hold harmless Locago and its
            officers, directors, agents and employees, from and against any claims, investigations,
            lawsuits, damages, losses, expenses, penalties and other liabilities, including without
            limitation attorney’s fees and costs, arising out of or relating to any of the
            following: (i) any breaches or alleged breaches by you of this Agreement or any of your
            representations and warranties contained herein; (ii) any tax obligations arising from
            the sale of your Products; (iii) any claims arising out of your violation or alleged
            violation of any law or regulation; (iv) any claims regarding infringement of
            intellectual property rights, including without limitation infringement claims arising
            out of Your IP and Third Party IP; and (v) any claims arising out of or relating to your
            Products, including without limitation, any claims for false advertising, product
            defects, personal injury, death, or property damages.
          </p>
          <p>
            17.  NO REPRESENTATIONS OR WARRANTIES BY LOCAGO. WE HAVE NOT MADE AND DO NOT MAKE ANY
            REPRESENTATIONS OR WARRANTIES CONCERNING THE LOCAGO MARKETPLACE. WITHOUT LIMITING THE
            FOREGOING, WE HAVE NOT MADE AND DO NOT MAKE ANY REPRESENTATIONS OR WARRANTIES CONCERNING
            (i) THE SIZE AND REACH OF THE LOCAGO MARKETPLACE, (ii) THE NUMBER OR QUALITY OF ITS
            USERS, (iii) THE SERVICE LEVELS AND AVAILABILITY OF THE LOCAGO MARKETPLACE; OR (iv) THE
            INCOME YOU MAY OR CAN MAKE USING THE LOCAGO MARKETPLACE.
          </p>
          <p>
            WE HAVE NOT MADE AND DO NOT MAKE ANY REPESENTATION OR WARRANTY THAT YOU WILL MAKE MONEY
            OR NOT LOSE ANY MONEY IN CONNECTION WITH YOUR PARTICIPATION IN THE LOCAGO MARKETPLACE.
          </p>
          <p>
            18.  WARRANTY DISCLAIMERS. LOCAGO DOES NOT MAKE ANY WARRANTY OF ANY KIND, EXPRESS OR
            IMPLIED TO YOU. LOCAGO PROVIDES THE LOCAGO MARKETPLACE “AS-IS” AND WITH ALL FAULTS.
            LOCAGO SPECIFICALLY DISCLAIMS THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A
            PARTICULAR PURPOSE, NON-INFRINGEMENT AND DATA ACCURACY. THERE IS NO WARRANTY OR
            GUARANTEE THAT THE OPERATION OF THIS SITE, THE LOCAGO MARKETPLACE AND/OR SERVICES
            PROVIDED BY THIS SITE WILL BE UNINTERRUPTED, ERROR-FREE OR VIRUS-FREE, OR THAT THIS
            SITE, THE LOCAGO MARKETPLACE AND/OR SERVICES PROVIDED BY THIS SITE WILL MEET ANY
            PARTICULAR CRITERIA OF PERFORMANCE, QUALITY OR ACCURACY. YOU ACKNOWLEDGE AND AGREE THAT
            LOCAGO HAS NO CONTROL OVER THE INTERNET, AND THAT WE ARE NOT LIABLE FOR THE
            DISCONTINUANCE OF OPERATION OF ANY PORTION OF THE INTERNET OR POSSIBLE REGULATION OF THE
            INTERNET WHICH MIGHT RESTRICT OR PROHIBIT THE OPERATION OF THIS SITE, THE LOCAGO
            MARKETPLACE OR ITS SERVICES.
          </p>
          <p>
            19.  LIABILITY LIMITATIONS. WE SHALL NOT BE LIABLE TO YOU UNDER ANY THEORY INCLUDING
            CONTRACT AND TORT (INCLUDING NEGLIGENCE AND PRODUCT LIABILITY) FOR ANY INDIRECT,
            SPECIAL, INCIDENTAL, CONSEQUENTIAL OR PUNITIVE DAMAGES, EVEN IF WE HAVE BEEN ADVISED OF
            THE POSSIBILITY OF SUCH DAMAGES.
          </p>
          <p>
            20.  MAXIMUM LIABILITY. IN ADDITION TO THE FOREGOING LIMITATIONS, IN NO EVENT SHALL THE
            AGGREGATE LIABILITY OF LOCAGO AND THIS SITE, INCLUDING WITHOUT LIMITATION LIABLITY
            ARISING OUT OF BREACH OF CONTRACT, TORT, STRICT LIABILITY, PRODUCT LIABILITY,
            NEGLIGENCE, BREACH OF WARRANTY OR OTHERWISE EXCEED THE LESSER OF (i) THE REMITTANCE PAID
            TO YOU DURING THE THIRTY-DAY PERIOD IMMEDIATELY PRECEDING THE OCCURRENCE OF THE
            LIABILITY AND (ii) US $10.00.
          </p>
          <p>
            21.  Confidentiality. The terms of this Agreement, including without limitation, the
            Selling Fees, the Subscription Fees and the Remittance Amounts, are confidential, and
            you agree to not disclose the terms described herein to any person other than your
            officers, directors, employees and shareholders on a need-to-know basis and only after
            undertaking reasonable precautions to preserve the confidentiality of the information
            made available to such persons. Any breach of this Section by you shall be considered a
            material breach of this Agreement and will result in irreparable and continuing damage
            to us for which there will be no adequate remedy at law. In the event of such breach, we
            will be entitled to injunctive relief and/or a decree for specific performance, and such
            other and further relief, including monetary damages, as a court, arbitrator or
            arbitrators may provide.
          </p>
          <p>
            22.  Customer Data. You must use and process all Customer Data in your possession in
            accordance with all applicable laws, and employ appropriate and effective security with
            respect to such Customer Data. You must notify us immediately if you become aware of or
            suspect any unauthorized access to or use of Customer Data in your possession, and you
            must cooperate with us in the investigation of such breach and the mitigation of any
            damages.
          </p>
          <p>
            23.  Information may not be Secure. You acknowledge and agree that any information you
            transmit through Locago Marketplace may not be secure, even if such information is
            encrypted, and that we are neither responsible nor liable for any resulting loss or
            damage.
          </p>
          <p>
            24.  Your IP and Third Party IP. You hereby grant to us for the Term and thereafter a
            non-exclusive, worldwide, royalty free, fully-paid, irrevocable, transferable and
            sublicensable right and license to possess, use, publicly display, distribute,
            broadcast, transmit, stream, publish, reproduce, sublicense, share and publicly perform:
            (i) your names, business names, images, likenesses, logos, trademarks, service marks,
            domain names, audiovisual contents, video recordings, audio recordings, photographs,
            graphics, artworks, texts, and any other content provided, specified, recommended,
            directed, authorized or approved by you for use with the Locago Marketplace
            (collectively “Your IP”); and (ii) any third-party’s names, business names, images,
            likenesses, logos, trademarks, service marks, domain names, audiovisual contents, video
            recordings, audio recordings, photographs, graphics, artworks, texts, and any other
            content provided, specified, recommended, directed, authorized or approved by you for
            use with the Locago Marketplace (collectively, “Third Party IP”), in all media or
            formats now known or hereafter developed. Our use of Your IP and Third Party IP is
            within our sole discretion.
          </p>
          <p>25.  Locago IP and Usage Data.</p>
          <p>
            a.     The Locago Marketplace and its contents, Usage Data, Customer Data, and our trade
            dress graphics, page headers, icons, names, business names, images, likenesses, logos,
            trademarks, service marks, domain names, audiovisual contents, video recordings, audio
            recordings, photographs, graphics, artworks, texts, and any other content (collectively
            the “Locago IP”) are protected by copyright, trademark and other intellectual property
            laws. Any unauthorized reproduction, modification, distribution, transmission,
            republication, display or performance of the Locago IP is strictly prohibited. You agree
            that nothing in this Agreement or on or in the Locago Marketplace shall be construed as
            granting, by implication, estoppel, or otherwise, any license or right to use any Locago
            IP or other copyrighted, trademarked or otherwise legally protected property displayed
            on the Locago Marketplace, except that during the Term you may use the Locago IP in
            accordance with this Agreement.
          </p>
          <p>
            b.     We may collect and analyze information related to this site’s visitors,
            customers, and your usage of the Locago Marketplace, including without limitation, the
            time spent using the Locago Marketplace, browser types and language, Internet Protocol
            addresses, device-specific information, including hardware models, operating systems and
            versions, unique device identifiers, mobile network information, information about the
            locations of device and customers and other software running in connection with the
            Locago Marketplace (collectively the “Usage Data”). As between you and us, we own all
            right, title and interest in and to any and all Usage Data, and we reserve the right to
            use and share Usage Data with third parties for business purposes.
          </p>
          <p>
            26.  Monitoring Your Activities on this Site. We have the right to monitor your
            activities on this site, including without limitation your marketing methods,
            procedures, and communications, but we have no right to control them.
          </p>
          <p>
            27.  No Liability for Third Party Content. We are not responsible for content or any
            other information posted to this site by third parties. We neither warrant the accuracy
            of such postings nor exercise any control over such posts, and we assume no legal or
            other obligation for them, including, without limitation, any responsibility or
            liability for investigating or verifying the accuracy of any content or any other
            information contained in such postings.
          </p>
          <p>28.  Messages and Notices.</p>
          <p>
            a.     We will send you emails and/or text messages to your designated phone number
            regarding the Locago Marketplace and the operation of this site.
          </p>
          <p>
            b.     We may give notice to you by means of electronic mail to your email address on
            record in Your Registration Information, or by written communication sent by first class
            mail or next business day courier to your address on record in Your Registration
            Information. You may give notice to us by means of electronic mail to the following
            email address: <a href="mailto:info@locago.shop">info@locago.shop</a>, or by written communication sent by first class mail or
            next business day courier to the following address:
          </p>
          <p>
            LOCAGO LLC
            <br />
            1890 Maple Ave.
            <br />
            #413E
            <br />
            Evanston, Illinois 60201
          </p>
           
          <p>
            Notices will not be effective unless sent in accordance with the above requirements.
          </p>
          <p>  29.  Term and Termination.</p>
          <p>
              a.     The term of this Agreement (the “Term”) will begin upon our issuance of written
            notice of acceptance to you as a participant in the Locago Marketplace in accordance
            with Section 2(a) and shall continue until terminated. Either you or we may terminate
            this Agreement at any time for convenience, with or without cause, by giving the
            non-terminating Party written notice of termination. The effective date of termination
            shall be the end of the month in which the termination notice is given. Notwithstanding
            the foregoing, we have the right in our sole discretion to immediately terminate your
            account with us and this Agreement if your account has been inactive for at least six
            (6) consecutive months while there was no use of your account for transactions.
          </p>
          <p>
            b.     Effect of Termination. The termination of this Agreement does not affect any
            rights or obligations that are to survive the termination of this Agreement under
            Sections 13, 15, 16, 19, 20, 21, 22, 24, 25, 27, 29, 35 and 36 or were incurred by the
            Parties before the termination. Upon the termination of this Agreement for any reason:
          </p>
          <p>
             i.     You will immediately cease use of, and remove from your site, all links to this
            site and all of our trademarks, trade dress and logos and all other materials provided
            by or on behalf of us to you in connection with the Locago Marketplace, and you may no
            longer indicate that you are a seller on the Locago Marketplace.
          </p>
          <p>
            ii.     You shall, as directed by us, destroy or return to us Customer Data in your
            possession.
          </p>
          <p>
            iii.     In accordance with Sections 9 and 10, you shall fulfill or deliver all orders
            which have a delivery or pickup date before the effective termination date. You may
            cancel all orders placed before the effective termination date if delivery or pickup is
            due thereafter.
          </p>
          <p>
            iv.     If any orders are cancelled or returned during the fourteen (14) day period
            following the effective termination date, both you and we shall process those
            cancellations and returns through the normal procedure as provided in Sections 10 and 12
            of this agreement.
          </p>
          <p>
            v.     You shall be liable for any unpaid subscription or other outstanding fees
            incurred on or before the effective date of termination.
          </p>
          <p>
            vi.     Except as explicitly set forth in this Section 29(b) (including, without
            limitation, those sections identified above as surviving the termination of this
            Agreement), neither you nor we shall be liable to the other Party for any compensation
            as a result of the termination of this Agreement, including compensation for loss of
            profits or goodwill.  
          </p>
          <p>
            30.  Force Majeure. Neither Party shall be liable for delay or failure in the
            performance of its obligations hereunder if such delay or failure arises from the
            occurrence of events beyond the reasonable control of such Party, including but not
            limited to, fire, explosion, strike, riot, terrorist activity, war, insurrection, acts
            of nature, epidemics, pandemics, outbreaks of communicable disease, viral outbreaks,
            quarantines or national or regional emergencies, actions of any governmental authority
            and acts of God. Notwithstanding the foregoing, any delay or failure exceeding thirty
            (30) days shall be grounds for termination by either Party.
          </p>
          <p>
            31.  Independent Contractor. The Parties are acting as independent contractors and no
            agency, partnership, joint venture or employer-employee relationship is created by this
            Agreement. Neither Party will have the power to bind or obligate the other Party.
          </p>
          <p>
            32.  Severability. If any provision of this Agreement is invalid, illegal or incapable
            of being enforced by any law or public policy, all other provisions of this Agreement
            will nevertheless remain in full force and effect.
          </p>
          <p>
            33.  Waiver. Our failure at any time or times to require your performance of any
            provision hereof shall in no manner affect the right to enforce the same. No waiver by
            us of any condition, or the breach of any provision contained in this Agreement, or
            whether by conduct or otherwise, in any one or more instances shall be deemed or
            construed as a further or continuing waiver of any such condition or breach or a waiver
            of any other condition or of the breach of any other provision herein or therein.
          </p>
          <p>
            34.  No Third Party Beneficiaries; Assignment. This Agreement shall be binding on the
            Parties and their respective successors and assigns and shall inure to the benefit of
            the Parties and their respective successors and permitted assigns. Nothing in this
            Agreement shall create or be deemed to create any third party beneficiary rights in any
            Person. Except for any transfer or assignment by either Party in connection with the
            sale or transfer of all or substantially all the business or assets of such Party,
            whether by sale of stock, sale of assets, merger or otherwise, no transfer or assignment
            of this Agreement or of any rights or obligations under this Agreement may be made by
            either Party without the prior written consent of the other Party, and any attempted
            transfer or assignment without that required consent shall be void.
          </p>
          <p>
            35.  Governing Law; Consent to Jurisdiction. This Agreement shall be governed by and
            construed in accordance with the laws of the State of Illinois without giving effect to
            any laws of such state that would make such choice of laws ineffective. Each of the
            Parties hereby irrevocably agrees that any legal action or proceeding with respect to
            this Agreement, this site, or the Locago Marketplace shall be brought and determined
            exclusively in the state or federal courts located in the State of Illinois and any
            state appellate court therefrom within the State of Illinois. Each of the Parties hereby
            irrevocably submits with regard to any such action or proceeding for itself and in
            respect of its property, generally and unconditionally, to the personal jurisdiction of
            the aforesaid courts and agrees that it will not bring any action relating to this
            Agreement or the transactions contemplated hereby in any court other than the aforesaid
            courts.  
          </p>
          <p>
            36.  Waiver of Jury Trial. EACH OF THE PARTIES HEREBY IRREVOCABLY WAIVES ALL RIGHT TO
            TRIAL BY JURY IN ANY ACTION OR COUNTERCLAIM (WHETHER BASED ON CONTRACT, TORT OR
            OTHERWISE) ARISING OUT OF OR RELATING TO THIS AGREEMENT OR THE TRANSACTIONS CONTEMPLATED
            HEREBY OR THE ACTIONS OF ANY PARTY IN THE NEGOTIATION, ADMINISTRATION, PERFORMANCE AND
            ENFORCEMENT OF THIS AGREEMENT.
          </p>
          <p>
            37.  Headings. The headings in this Agreement are included for convenience of reference
            and shall not be used in the interpretation of this Agreement.
          </p>
        </div>
      </div>
    </LayoutSingleColumn>
  );
};
export default TermsOfServicesPage;
