import React from 'react';
import { AspectRatioWrapper, Button, H4, NamedLink, ResponsiveImage } from '../../components';
import { lazyLoadWithDimensions } from '../../util/uiHelpers';
import { ensureListing } from '../../util/data';

import css from './ShopListing.module.css';
import nextarrow from '../../assets/nextarrow.svg';
import { richText } from '../../util/richText';
import { displayPrice } from '../../util/configHelpers';
import { isBookingProcessAlias } from '../../transactions/transaction';
import { formatMoney } from '../../util/currency';
import { FormattedMessage } from 'react-intl';
import { createSlug } from '../../util/urlHelpers';
import { useConfiguration } from '../../context/configurationContext';
import { isStoreOwner } from '../../util/userHelpers';
import { logLikeItemEvent } from '../../util/likeEvent';

const LazyImage = lazyLoadWithDimensions(ResponsiveImage, { loadAfterInitialRendering: 3000 });

const MIN_LENGTH_FOR_LONG_WORDS = 10;

const priceData = (price, currency, intl) => {
    if (price && price.currency === currency) {
        const formattedPrice = formatMoney(intl, price);
        return { formattedPrice, priceTitle: formattedPrice };
    } else if (price) {
        return {
            formattedPrice: intl.formatMessage(
                { id: 'ListingCard.unsupportedPrice' },
                { currency: price.currency }
            ),
            priceTitle: intl.formatMessage(
                { id: 'ListingCard.unsupportedPriceTitle' },
                { currency: price.currency }
            ),
        };
    }
    return {};
};

const PriceMaybe = props => {
    const { price, publicData, config, intl } = props;
    const { listingType } = publicData || {};
    const validListingTypes = config.listing.listingTypes;
    const foundListingTypeConfig = validListingTypes.find(conf => conf.listingType === listingType);
    const showPrice = displayPrice(foundListingTypeConfig);
    if (!showPrice && price) {
        return null;
    }

    const isBookable = isBookingProcessAlias(publicData?.transactionProcessAlias);
    const { formattedPrice, priceTitle } = priceData(price, config.currency, intl);
    return (
        <div className={css.price}>
            <div className={css.priceValue} title={priceTitle}>
                {formattedPrice}
            </div>
            {isBookable ? (
                <div className={css.perUnit}>
                    <FormattedMessage id="ListingCard.perUnit" values={{ unitType: publicData?.unitType }} />
                </div>
            ) : null}
        </div>
    );
};

const ShopListing = ({
    listings,
    intl,
    storeName,
    ensuredCurrentUser,
    onToggleFavorites,
    profileUser = null,
    page = ''
}) => {

    const config = useConfiguration();
    const isListingPage = page === 'ListingPage';

    return (
        <div className={` ${css.container} ${isListingPage
            ? css.containerListingMain : ""}`}>
            <div className={`${css.sliderSection} ${isListingPage
                ? css.sliderSectionBorder : ""}`}>
                <div className={` ${css.productHeader} ${isListingPage
                    ? css.productHeaderListing : ""}`}>
                    <H4 as="h2" className={css.sliderHeading}>{storeName}</H4>
                    {
                        profileUser
                            ? <Button className={css.headerBtn}>
                                <a href={`/s?authorId=${profileUser?.id?.uuid}`}>
                                    <img src={nextarrow} alt={'next arrow'} className={css.arrow} />
                                </a>
                            </Button>
                            : null
                    }

                </div>
                <div className={css.sliderContainer}>
                    <div className={css.slider}>
                        {listings?.slice(0, 4)?.map((listing, index) => {


                            const currentListing = ensureListing(listing);
                            const id = currentListing.id.uuid;
                            const { title = '', price, publicData } = currentListing.attributes;
                            const slug = createSlug(title);
                            const firstImage =
                                currentListing.images && currentListing.images.length > 0 ? currentListing.images[0] : null;

                            const {
                                aspectWidth = 1,
                                aspectHeight = 1,
                                variantPrefix = 'listing-card',
                            } = config.layout.listingImage;
                            const variants = firstImage
                                ? Object.keys(firstImage?.attributes?.variants).filter(k => k.startsWith(variantPrefix))
                                : [];

                            const { privateData = {}, publicData: currentUserPublicData = {} } = ensuredCurrentUser?.attributes?.profile || {};
                            const isFavorite = privateData?.favorites?.includes(id);

                            const toggleFavorites = () => {
                                 logLikeItemEvent(title, isFavorite)
                                onToggleFavorites(isFavorite, id)
                            };

                            return (
                                <div key={index} index={index} className={css.slideborder}>
                                    <div className={css.sliderItem}>
                                        <div className={css.sliderImage}>

                                            <AspectRatioWrapper
                                                className={css.aspectRatioWrapper}
                                                width={aspectWidth}
                                                height={aspectHeight}
                                            >
                                                <NamedLink name="ListingPage" params={{ id, slug }}>
                                                    <LazyImage
                                                        rootClassName={css.rootForImage}
                                                        alt={title}
                                                        image={firstImage}
                                                        variants={variants}
                                                    />
                                                </NamedLink>
                                            </AspectRatioWrapper>
                                            {
                                                !isStoreOwner(currentUserPublicData?.userType)
                                                    ? <button type='button' onClick={toggleFavorites} className={css.favouriteBtn}>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                            <path
                                                                d="M10.567 19.0534L10.5662 19.0527C8.05943 16.7796 6.01894 14.9259 4.59931 13.1891C3.18518 11.459 2.43945 9.90601 2.43945 8.24251C2.43945 5.52289 4.56167 3.40918 7.27279 3.40918C8.80891 3.40918 10.2915 4.12686 11.257 5.25179L11.6364 5.69386L12.0158 5.25179C12.9813 4.12686 14.4639 3.40918 16.0001 3.40918C18.7112 3.40918 20.8334 5.52289 20.8334 8.24251C20.8334 9.90601 20.0877 11.459 18.6735 13.1891C17.2539 14.9259 15.2134 16.7796 12.7066 19.0527L12.7059 19.0534L11.6364 20.027L10.567 19.0534Z"
                                                                fill={isFavorite ? "#973132" : "white"}
                                                                stroke="#973132" />
                                                        </svg>
                                                    </button>
                                                    : null
                                            }
                                        </div>
                                        <NamedLink name="ListingPage" params={{ id, slug }}>
                                            <div className={css.info}>
                                                <div className={css.mainInfo}>
                                                    {
                                                        isListingPage
                                                            ? <div>
                                                                <p className={css.listingTitle}>{currentListing?.author?.attributes?.profile?.displayName}</p>
                                                            </div>
                                                            : null
                                                    }
                                                    <div className={css.title}>
                                                        {richText(title, {
                                                            longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS,
                                                            longWordClass: css.longWord,
                                                        })}
                                                    </div>
                                                    <div className={css.price}>
                                                        <PriceMaybe price={price} publicData={publicData} config={config} intl={intl} />
                                                    </div>
                                                </div>
                                            </div>
                                        </NamedLink>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                    {
                        profileUser
                            ? <Button className={css.btn}>
                                <a href={`/s?authorId=${profileUser?.id?.uuid}`}>
                                    <img src={nextarrow} alt={'next arrow'} className={css.arrow} />
                                </a>
                            </Button>
                            : null
                    }

                </div>
            </div>
        </div>
    );
};

export default ShopListing;