export const logLikeItemEvent = (listingTitle, isFavorite) => {
    if (window.gtag && !isFavorite) {
      window.gtag('event', `liked_item: ${listingTitle}`, {
        event_category: 'like_item',
      });
    }
  };
  
 export  const logLikedStoreEvent = (displayName, isFavorite) => {
    if (window.gtag && !isFavorite) {
      window.gtag('event', `liked_store: ${displayName}`, {
        event_category: 'liked_store',
      });
    }
  };