/**
 *  TopbarMobileMenu prints the menu content for authenticated user or
 * shows login actions for those who are not authenticated.
 */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { ACCOUNT_SETTINGS_PAGES } from '../../../../routing/routeConfiguration';
import { FormattedMessage } from '../../../../util/reactIntl';
import { propTypes } from '../../../../util/types';
import { ensureCurrentUser } from '../../../../util/data';
import { isStoreOwner } from '../../../../util/userHelpers';
import { Link } from 'react-router-dom';
import accountIcon from '../../../../containers/LandingPage/icons/accountDetails.png';
import likesIcon from '../../../../containers/LandingPage/icons/likes.png';
import cartIcon from '../../../../containers/LandingPage/icons/cartIcon.png';
import SearchIcon from '../SearchIcon';
import backIcon from '../../../../containers/LandingPage/icons/backArrow.png';
import envelope from '../../../../containers/LandingPage/icons/envelope.svg';

import {
  AvatarLarge,
  ExternalLink,
  InlineTextButton,
  NamedLink,
  NotificationBadge,
  Button,
} from '../../../../components';

import css from './TopbarMobileMenu.module.css';
import { mainListingCategories } from '../../../../util/mainLisitngCategories';
const CustomLinkComponent = ({ linkConfig, currentPage }) => {
  const { group, text, type, href, route } = linkConfig;
  const getCurrentPageClass = page => {
    const hasPageName = name => currentPage?.indexOf(name) === 0;
    const isCMSPage = pageId => hasPageName('CMSPage') && currentPage === `${page}:${pageId}`;
    const isInboxPage = tab => hasPageName('InboxPage') && currentPage === `${page}:${tab}`;
    const isCurrentPage = currentPage === page;

    return isCMSPage(route?.params?.pageId) || isInboxPage(route?.params?.tab) || isCurrentPage
      ? css.currentPage
      : null;
  };

  // Note: if the config contains 'route' keyword,
  // then in-app linking config has been resolved already.
  if (type === 'internal' && route) {
    // Internal link
    const { name, params, to } = route || {};
    const className = classNames(css.navigationLink, getCurrentPageClass(name));
    return (
      <NamedLink name={name} params={params} to={to} className={className}>
        <span className={css.menuItemBorder} />
        {text}
      </NamedLink>
    );
  }
  return (
    <ExternalLink href={href} className={css.navigationLink}>
      <span className={css.menuItemBorder} />
      {text}
    </ExternalLink>
  );
};

const TopbarMobileMenu = props => {
  const {
    isAuthenticated,
    currentPage,
    currentUserHasListings,
    currentUser,
    notificationCount,
    customLinks,
    onLogout,
    intl,
    handleMobileSearchOpen,
    isMainMenu,
    setIsMainMenu,
  } = props;

  const userType = currentUser?.attributes?.profile?.publicData?.userType || 'not logged in';
  const isManualInventory = currentUser?.attributes?.profile?.publicData.isManualInventory || false;

  const user = ensureCurrentUser(currentUser);

  const [isAccountSetting, setIsAccountSetting] = useState(false);

  const extraLinks = customLinks.map(linkConfig => {
    return (
      <CustomLinkComponent
        key={linkConfig.text}
        linkConfig={linkConfig}
        currentPage={currentPage}
      />
    );
  });

  const notificationCountBadge =
    notificationCount > 0 ? (
      <NotificationBadge className={css.notificationBadge} />
    ) : null;

  const displayName = user.attributes.profile.firstName;
  const currentPageClass = page => {
    const isAccountSettingsPage =
      page === 'AccountSettingsPage' && ACCOUNT_SETTINGS_PAGES.includes(currentPage);
    const isInboxPage = currentPage?.indexOf('InboxPage') === 0 && page?.indexOf('InboxPage') === 0;
    return currentPage === page || isAccountSettingsPage || isInboxPage ? css.currentPage : null;
  };
  const inboxTab = currentUserHasListings ? 'sales' : 'orders';

  const filteredCategoriesLisitng = mainListingCategories.filter(category => category.label !== "Home & Decor")

  return (
    <div className={css.root}>
      <div className={css.accountsInformationContainer}>
        {isAuthenticated && (
          <div className={css.accountsInformationMain}>
            <span onClick={() => setIsMainMenu(true)} className={css.accountButton}>
              <svg width="23px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="size-6">
                <path fill-rule="evenodd" d="M7.5 6a4.5 4.5 0 1 1 9 0 4.5 4.5 0 0 1-9 0ZM3.751 20.105a8.25 8.25 0 0 1 16.498 0 .75.75 0 0 1-.437.695A18.683 18.683 0 0 1 12 22.5c-2.786 0-5.433-.608-7.812-1.7a.75.75 0 0 1-.437-.695Z" clip-rule="evenodd" />
              </svg>
              {notificationCountBadge}
            </span>

            {!isStoreOwner(userType) && (
              <a href="/my-likes" className={css.likesIconSvg}>
                <svg width="20" height="20" viewBox="0 0 60 55" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M30 55.0032L25.65 51.0466C10.2 37.0485 0 27.7864 0 16.486C0 7.22386 7.26 0 16.5 0C21.72 0 26.73 2.42794 30 6.2347C33.27 2.42794 38.28 0 43.5 0C52.74 0 60 7.22386 60 16.486C60 27.7864 49.8 37.0485 34.35 51.0466L30 55.0032Z" fill="black" />
                </svg>

              </a>
            )}

            {!isStoreOwner(userType) && (
              <a href="/cart">
                <svg width="21" height="24" viewBox="0 0 69 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M20.7 48C16.8878 48 13.8345 50.685 13.8345 54C13.8345 57.315 16.8878 60 20.7 60C24.5123 60 27.6 57.315 27.6 54C27.6 50.685 24.5123 48 20.7 48ZM0 0V6H6.9L19.3028 28.755L14.6453 36.105C14.1105 36.975 13.8 37.95 13.8 39C13.8 42.315 16.8878 45 20.7 45H62.1V39H22.1663C21.6833 39 21.3038 38.67 21.3038 38.25C21.3038 38.115 21.3382 37.995 21.4072 37.89L24.495 33H50.1975C52.785 33 55.0448 31.755 56.235 29.91L68.5688 10.44C68.8448 10.02 69 9.525 69 9C69 8.20435 68.6365 7.44129 67.9895 6.87868C67.3425 6.31607 66.465 6 65.55 6H14.5418L11.2643 0H0ZM55.2 48C51.3878 48 48.3345 50.685 48.3345 54C48.3345 57.315 51.3878 60 55.2 60C59.0123 60 62.1 57.315 62.1 54C62.1 50.685 59.0123 48 55.2 48Z" fill="black" />
                </svg>
              </a>
            )}
          </div>
        )}
        <div className={css.mobileNavContainer}>
          <Button
            rootClassName={css.searchMenu}
            onClick={handleMobileSearchOpen}
            title={intl.formatMessage({ id: 'Topbar.searchIcon' })}
          >
            <SearchIcon className={css.searchMenuIcon} />
          </Button>
        </div>
      </div>

      {!isMainMenu && !isAccountSetting ? (
        <div className={css.content}>
          {filteredCategoriesLisitng.map(category => {
            return (
              <div key={category.id} className={css.contentAccessoriesContainer}>
                <a href={category.link}>{category.label}</a>{' '}
              </div>
            );
          })}
          {isAuthenticated ? (
            <InlineTextButton rootClassName={css.logoutButton} onClick={onLogout}>
              <FormattedMessage id="TopbarMobileMenu.logoutLink" />
            </InlineTextButton>
          ) : (
            <NamedLink name="LoginPage" className={css.loginLink}>
              <FormattedMessage id="TopbarMobileMenu.loginLink" />
            </NamedLink>
          )}
        </div>
      ) : isMainMenu ? (
        <div className={css.contentAccountMain}>
          <div onClick={() => setIsMainMenu(false)} className={css.backButton}>
            {' '}
            <img src={backIcon} alt="Back" />
            Back{' '}
          </div>
          <div className={css.accountLinksWrapper}>
            <Link
              className={classNames(css.inbox, currentPageClass(`InboxPage:${inboxTab}`))}
              to={`/orders`}
            >
              Orders
            </Link>

            {isStoreOwner(userType) ? (
              <Link
                className={classNames(css.navigationLink, currentPageClass('ManageListingsPage'))}
                to={`/listings`}
              >
                Listings
              </Link>
            ) : null}
            <Link
              className={classNames(css.messageContainer, currentPageClass(`InboxPage:${inboxTab}`))}
              to="/messages"
            >
              Messages
              {notificationCountBadge}
            </Link>
            <div
              className={css.contentAccessoriesContainer}
              onClick={() => {
                setIsAccountSetting(true);
                setIsMainMenu(false);
              }}
            >
              Account Settings
            </div>
            {isStoreOwner(userType) ? (
              <NamedLink
                className={classNames(css.navigationLink, currentPageClass('ProfileSettingsPage'))}
                name="ProfileSettingsPage"
              >
                <FormattedMessage id="TopbarMobileMenu.profileSettingsLink" />
              </NamedLink>
            ) : null}
            <InlineTextButton rootClassName={css.logoutButton} onClick={onLogout}>
              <FormattedMessage id="TopbarMobileMenu.logoutLink" />
            </InlineTextButton>
          </div>
          <div className={css.customLinksWrapper}>{extraLinks}</div>
          <div className={css.spacer} />
        </div>
      ) : (
        <div className={css.contentAccountMain}>
          <div
            onClick={() => {
              setIsMainMenu(true);
              setIsAccountSetting(false);
            }}
            className={css.backButton}
          >
            {' '}
            <img src={backIcon} alt="Back" />
            Back{' '}
          </div>
          <div className={css.contentAccountMainData}>
            <div className={css.contentAccessoriesContainer}>
              <a href="/account/contact-details">Contact Details</a>{' '}
            </div>
            <div className={css.contentAccessoriesContainer}>
              <a href="/account/change-password">Password</a>{' '}
            </div>
            {isStoreOwner(userType) ? (
              <div className={css.contentAccessoriesContainer}>
                <a href="/account/payments">Payout details</a>{' '}
              </div>
            ) : (
              <div className={css.contentAccessoriesContainer}>
                <a href="/account/payment-methods">Payment methods</a>{' '}
              </div>
            )}
            {isStoreOwner(userType) &&
              <div className={css.contentAccessoriesContainer}>
                <a href="/terms-of-service#marketplaceAgreementId">Marketplace Agreement</a>{' '}
              </div>}
            <InlineTextButton rootClassName={css.logoutButton} onClick={onLogout}>
              <FormattedMessage id="TopbarMobileMenu.logoutLink" />
            </InlineTextButton>
          </div>
        </div>
      )}
      <div className={css.footer}>
        {isStoreOwner(userType) && isManualInventory ? (
          <div className={css.footer}>
            <NamedLink className={css.createNewListingLink} name="NewListingPage">
              <FormattedMessage id="TopbarMobileMenu.newListingLink" />
            </NamedLink>
          </div>
        ) : null}
      </div>
    </div>
  );
};

TopbarMobileMenu.defaultProps = {
  currentUser: null,
  notificationCount: 0,
  currentPage: null,
};

const { bool, func, number, string } = PropTypes;

TopbarMobileMenu.propTypes = {
  isAuthenticated: bool.isRequired,
  currentUserHasListings: bool.isRequired,
  currentUser: propTypes.currentUser,
  currentPage: string,
  notificationCount: number,
  onLogout: func.isRequired,
};

export default TopbarMobileMenu;
